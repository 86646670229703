import React, { useEffect, useState } from 'react';
//redux
import { useSelector } from 'react-redux';
//CoreUi
import { CAlert, CToast, CToastBody, CToaster } from '@coreui/react';
//Selectors
import { selectNotification } from '../../redux/notification/Selector';

function Notification() {
  const notification = useSelector(selectNotification);
  const style = notification.type || 'error';
  const [key, setKey] = useState(1);
  const newKey = () => setKey(key + 1);

  useEffect(newKey, [notification]);

  const toasts = { position: 'bottom-right', autohide: 2000 };

  let color = 'danger';
  if (style === 'success') {
    color = 'success';
  } else if (style === 'info') {
    color = 'info';
  } else if (style === 'notif') {
    color = 'primary';
  } else if (style === 'warning') {
    color = 'warning';
  }
  return notification && notification.message ? (
    <CToaster color={color} position={toasts.position} className={color}>
      <CToast key={key} show={true} autohide={toasts.autohide} fade={true}>
        <CToastBody color={color}>
          <CAlert color={color}>{notification.message}</CAlert>
        </CToastBody>
      </CToast>
    </CToaster>
  ) : null;
}

export default Notification;
