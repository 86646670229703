export const GET_NODES = 'GET_NODES';
export const SET_SELECTED_NODE = 'SET_SELECTED_NODE';
export const SET_SELECTED_SENSORS = 'SET_SELECTED_SENSORS';
export const SET_SELECTED_NODE_METRICS = 'SET_SELECTED_NODE_METRICS';
export const SET_SELECTED_NODE_LONG_TERM_METRICS =
  'SET_SELECTED_NODE_LONG_TERM_METRICS';
export const CLEAR_SELECTED_NODE_LONG_TERM_METRICS =
  'CLEAR_SELECTED_NODE_LONG_TERM_METRICS';
export const GET_NODE = 'GET_NODE';
export const CLEAR_SELECTED_NODE_METRICS = 'CLEAR_SELECTED_NODE_METRICS';
export const GET_NODE_SENSOR = 'GET_NODE_SENSOR';
export const SET_MEASUREMENT_TYPE = 'SET_MEASUREMENT_TYPE';

export const CLEAR_SELECTED_NODE_CFW_CYCLE_HISTORY =
  'CLEAR_SELECTED_NODE_CFW_CYCLE_HISTORY';

export const SET_SELECTED_NODE_CFW_CYCLE_HISTORY =
  'SET_SELECTED_NODE_CFW_CYCLE_HISTORY';

export const SET_SELECTED_CFW_CYCLE_HISTORY = 'SET_SELECTED_CFW_CYCLE_HISTORY';
