import { GET_NOTIFICATIONS, GET_NOTIFICATION, ERROR } from './Types';

const initState = {
  list: {
    items: null,
    totalCount: 0,
  },
  notification: null,
  types: null,
  modes: null,
  errors: null,
};

export const NotificationReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
