import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
//redux
import { connect } from 'react-redux';
//Style
import './assets/scss/style.scss';
import './assets/scss/main.scss';
import './assets/scss/dark.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import 'antd/dist/antd.css';
// Components
import Notification from './core/notification/Notification';
import PropTypes from 'prop-types';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./pages/auth/Login'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const RegisterUser = React.lazy(() => import('./pages/auth/RegisterUser'));
const Forgot = React.lazy(() => import('./pages/auth/Forgot'));
const NewPassword = React.lazy(() => import('./pages/auth/NewPassword'));
const Page404 = React.lazy(() => import('./pages/errors/Page404'));
const Page500 = React.lazy(() => import('./pages/errors/Page500'));
const LandingPage = React.lazy(() => import('./pages/Landing/Landing'));
const TechnolagyPage = React.lazy(() =>
  import('./pages/Technolagy/Technolagy')
);

function App({ token }) {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={() =>
              token ? <Redirect to="/nodes-sensors" /> : <Login />
            }
          />
          <Route
            exact
            path="/home"
            name="Landing Page"
            render={() => <LandingPage />}
          />
          <Route
            exact
            path="/TechnolagyPage"
            name="Technolagy Page"
            render={() => <TechnolagyPage />}
          />
          <Route
            exact
            path="/accounts/verify"
            name="Login Page"
            render={props => <Login {...props} />}
          />
          <Route
            exact
            path="/organizations/register"
            name="Register Page"
            render={props => <Register {...props} />}
          />
          <Route
            exact
            path={['/register', '/organizations/accounts/register']}
            name="Register Page"
            render={props => <RegisterUser {...props} />}
          />
          <Route
            exact
            path="/forgot"
            name="Forgot Page"
            render={props => <Forgot {...props} />}
          />
          <Route
            exact
            path="/changePassword"
            name="Forgot Page"
            render={props => <NewPassword {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={props => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={props => <Page500 {...props} />}
          />
          <Route
            path="/apps/email"
            name="Email App"
            render={props => <TheEmailApp {...props} />}
          />
          <Route
            path="/"
            render={() => (token ? <TheLayout /> : <Redirect to="/home" />)}
          />
        </Switch>
        <Notification />
      </React.Suspense>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  };
};

App.propTypes = {
  token: PropTypes.string,
};

export default connect(mapStateToProps, null)(App);
