export const GET_ALERTS = 'GET_ALERTS';
export const GET_ALERT = 'GET_ALERT';
export const GET_ALERT_TYPE = 'GET_ALERT_TYPE';
export const GET_MODE_TYPE = 'GET_MODE_TYPE';
export const ERROR = 'PERSON_ERROR';
export const ADD_NOT = 'ADD_NOTIFICATION';
export const CLEAR_NOT = 'CLEAR_NOTIFICATION';
export const SET_ALERT_DATA = 'SET_ALERT_DATA';
export const NEW_ALERT = 'NEW_ALERT';
export const EDITABLE = 'EDITABLE';
export const RESET = 'RESET';
export const RESET_TRIGGER = 'RESET_TRIGGER';
export const GET_NODES = 'GET_NODES';
