import {
  GET_RESULT,
  GET_REPORT,
  SET_DATE,
  SET_TYPE,
  SET_DEVICE,
  SET_DISPLAY,
  GET_COMPARE_RESULT,
} from './Types';

const initState = {
  date: null,
  result: null,
  measurementType: { label: 'Flow vs Time', value: 'NORMAL' },
  deviceType: null,
  displayType: 'grouped',
};

export const AnalyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    case GET_COMPARE_RESULT:
      return {
        ...state,
        compareResult: action.payload,
      };
    case GET_REPORT:
      return {
        ...state,
        report: action.payload,
      };
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case SET_TYPE:
      return {
        ...state,
        measurementType: action.payload,
      };
    case SET_DEVICE:
      return {
        ...state,
        deviceType: action.payload,
      };
    case SET_DISPLAY:
      return {
        ...state,
        displayType: action.payload,
      };
    default:
      return state;
  }
};
