const initialState = {};

export const Pagination = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'pagination':
      return { ...state, ...payload };
    default:
      return state;
  }
};
