import {
  GET_CLIENTS,
  GET_CLIENT,
  ERROR,
  SET_CLIENT_EDITABLE,
  GET_CLIENTS_AUTH,
} from './Types';

const initState = {
  list: null,
  client: null,
  types: null,
  modes: null,
  errors: null,
};

export const ClientsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_CLIENTS_AUTH:
      return {
        ...state,
        clientsAuth: action.payload,
      };
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case SET_CLIENT_EDITABLE:
      return {
        ...state,
        editable: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
