import {
  GET_DEVICES,
  GET_DEVICE,
  SELECT_DEVICE,
  DELETE_DEVICE,
  ADD_DEVICE,
  SET_DEVICE_EDITABLE,
  GET_DEVICE_SENSOR,
  SET_DEVICE_SENSOR_RANGE,
  CLEAR_SENSOR_DATA,
  SET_CURRENT_DEVICE,
  RESET_DEVICE_FORM,
  GET_DEVICE_TYPES,
  GET_DEVICE_CONFIG,
  SET_SELETED_DEVICE_TYPE,
} from './Types';

const initState = {
  list: null,
  current: null,
  sensor: {
    TODAY: null,
    YESTERDAY: null,
    LAST_WEEK: null,
    LAST_MONTH: null,
  },
  range: 'TODAY',
  selected: null,
  editable: null,
  types: null,
  selectedDeviceType: '',
};

export const DeviceReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DEVICES:
      return {
        ...state,
        list: action.payload,
      };
    case GET_DEVICE:
      return {
        ...state,
        current: action.payload,
      };
    case GET_DEVICE_SENSOR: {
      let sensorData = { ...state.sensor };
      sensorData[action.payload.range] = action.payload.data;
      return {
        ...state,
        sensor: sensorData,
      };
    }
    case CLEAR_SENSOR_DATA:
      return {
        ...state,
        sensor: initState.sensor,
        range: initState.range,
      };
    case SET_DEVICE_SENSOR_RANGE:
      return {
        ...state,
        range: action.payload,
      };
    case ADD_DEVICE:
      return {
        ...state,
        list: [...state.list, action.payload],
        current: action.payload,
      };

    case SELECT_DEVICE:
      return {
        ...state,
        selected: action.payload,
      };
    case DELETE_DEVICE:
      return {
        ...state,
        list: state.list.filter(item => item.uuid !== action.payload),
      };

    case SET_DEVICE_EDITABLE:
      return {
        ...state,
        editable: action.payload,
      };

    case GET_DEVICE_TYPES:
      return {
        ...state,
        types: action.payload,
      };
    case GET_DEVICE_CONFIG:
      return {
        ...state,
        deviceConfig: action.payload,
      };

    case SET_CURRENT_DEVICE:
      return {
        ...state,
        current: action.payload,
      };

    case SET_SELETED_DEVICE_TYPE:
      return {
        ...state,
        selectedDeviceType: action.payload,
      };

    case RESET_DEVICE_FORM:
      return {
        ...state,
        editable: null,
      };
    default:
      return state;
  }
};
