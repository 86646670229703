import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import Reducers from './Reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerReducer, syncHistoryWithStore } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';

const store = createStore(
  combineReducers({
    ...Reducers,
    form: formReducer,
    routing: routerReducer,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

const hashHistory = createBrowserHistory();
const history = syncHistoryWithStore(hashHistory, store);

export { store, history };
