import { DARK } from '../../config/Config.json';

const initialState = {
  sidebarShow: false,
  asideShow: false,
  darkMode: DARK,
  loading: false,
};

export const SettingsReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    default:
      return state;
  }
};
