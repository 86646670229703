import {
  GET_NODES,
  SET_SELECTED_NODE,
  GET_NODE_SENSOR,
  SET_MEASUREMENT_TYPE,
  SET_SELECTED_SENSORS,
  SET_SELECTED_NODE_METRICS,
  CLEAR_SELECTED_NODE_METRICS,
  SET_SELECTED_NODE_CFW_CYCLE_HISTORY,
  CLEAR_SELECTED_NODE_CFW_CYCLE_HISTORY,
  SET_SELECTED_CFW_CYCLE_HISTORY,
  SET_SELECTED_NODE_LONG_TERM_METRICS,
  CLEAR_SELECTED_NODE_LONG_TERM_METRICS,
} from './Types';

const initialSensors = [
  {
    label: 'Water volume',
    value: 'PULSE_METER',
    order: 1,
  },
  {
    label: 'Water depth',
    value: 'WATER_LEVEL',
    order: 2,
  },
  {
    label: 'Ambient Temperature',
    value: 'EXTERNAL_TEMPERATURE',
    order: 3,
  },
  {
    label: 'Ambient Humidity',
    value: 'EXTERNAL_HUMIDITY',
    order: 4,
  },
  {
    label: 'LUX',
    value: 'LIGHT',
    order: 5,
  },
  {
    label: 'SOIL TEMPERATURE',
    value: 'SOIL_TEMPERATURE',
    order: 6,
  },
  {
    label: 'SOIL MOISTURE',
    value: 'SOIL_SALINITY',
    order: 7,
  },
  {
    label: 'Internal Temperature',
    value: 'INTERNAL_TEMPERATURE',
    order: 8,
  },
];

const initState = {
  nodes: {
    items: null,
    totalCount: 0,
  },
  initialNode: null,
  selectedNode: null,
  selectedNodeMetrics: null,
  selectedNodeLongTermMetrics: null,
  selectedCfwCycleHistory: null,
  cfwCycleHistory: [],
  selectedSensors: initialSensors,
  sensors: {
    TODAY: null,
    YESTERDAY: null,
    LAST_WEEK: [],
    LAST_MONTH: null,
  },
  range: 'TODAY',
  measurementType: { label: 'Flow vs Time', value: 'NORMAL' },
  results: null,
  selected: null,
  editable: null,
  types: null,
  selectedDeviceType: '',
};

export const SensorsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NODES:
      return {
        ...state,
        nodes: action.payload,
      };

    case SET_SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload,
      };
    case SET_SELECTED_NODE_METRICS:
      return {
        ...state,
        selectedNodeMetrics: action.payload,
      };
    case SET_SELECTED_NODE_LONG_TERM_METRICS:
      return {
        ...state,
        selectedNodeLongTermMetrics: action.payload,
      };
    case CLEAR_SELECTED_NODE_LONG_TERM_METRICS:
      return {
        ...state,
        selectedNodeLongTermMetrics: null,
      };
    case CLEAR_SELECTED_NODE_METRICS:
      return {
        ...state,
        selectedNodeMetrics: null,
      };
    case SET_SELECTED_SENSORS: {
      const payload = action.payload.map(sensor => {
        const findSensor = initialSensors.find(
          item => item.value === sensor.value
        );
        return findSensor ? findSensor : sensor;
      });

      return {
        ...state,
        selectedSensors: payload,
      };
    }
    case GET_NODE_SENSOR: {
      return {
        ...state,
        results: action.payload,
      };
    }
    case SET_MEASUREMENT_TYPE:
      return {
        ...state,
        measurementType: action.payload,
      };
    case SET_SELECTED_NODE_CFW_CYCLE_HISTORY: {
      return {
        ...state,
        cfwCycleHistory: action.payload,
      };
    }
    case SET_SELECTED_CFW_CYCLE_HISTORY: {
      return {
        ...state,
        selectedCfwCycleHistory: action.payload,
      };
    }
    case CLEAR_SELECTED_NODE_CFW_CYCLE_HISTORY:
      return {
        ...state,
        selectedCfwCycleHistory: null,
        cfwCycleHistory: [],
      };
    default:
      return state;
  }
};
