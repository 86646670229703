import {
  POST_METERLIST,
  GET_METERLIST,
  SET_METER_LIST_EDIT,
  ERROR,
} from './Types';

const initialState = {
  meterlist: null,
  errors: null,
  types: null,
};

export const MeterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_METERLIST:
      return {
        ...state,
        types: action.payload,
      };
    case GET_METERLIST:
      return {
        ...state,
        meterlist: action.payload,
      };
    case SET_METER_LIST_EDIT:
      return {
        ...state,
        editable: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
