import {
  PERIOD,
  CHOSE,
  DATE,
  CAL_DATE,
  COMP_DATE,
  COMPARE_PERIOD,
  SET_COMPARE,
} from './Types.js';

const initialState = {
  period: null,
  comparePeriod: null,
  date: null,
  chose: null,
  compare: null,
};

export const DateReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PERIOD:
      return { ...state, period: payload };
    case COMPARE_PERIOD:
      return { ...state, comparePeriod: payload };
    case CHOSE:
      return { ...state, chose: payload };
    case DATE:
      return { ...state, date: payload };
    case CAL_DATE:
      return { ...state, caldate: payload };
    case COMP_DATE:
      return { ...state, compdate: payload };
    case SET_COMPARE:
      return { ...state, compare: payload };
    default:
      return state;
  }
};
