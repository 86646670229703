import jwt from 'jsonwebtoken';
import { LOGIN, LOGOUT, SET_USER } from './Types';
import { getToken } from '../../helpers/Auth';

const token = getToken();
const decodedToken = jwt.decode(token);

const initState = {
  user: decodedToken,
  token,
};

export const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
      };
    default:
      return state;
  }
};
