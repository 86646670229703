import { AuthReducer } from './auth/AuthReducer';
import { Notification } from './notification/NotificationReducer';
import { Pagination } from './pagination/PaginationReducer.js';
import { SettingsReducer } from './settings/SettingsReducer';
import { AnalyticsReducer } from './analytics/AnalyticsReducer';
import { UserReducer } from './user/UserReducer';
import { ClientsReducer } from './clients/ClientsReducer';
import { DateReducer } from './date/DateReducer';
import { DeviceReducer } from './devices/DeviceReducer';
import { PeakReducer } from './peak/PeakReducer';
import { MeterListReducer } from './meter-list/MeterListReducer';
import { AlertReducer } from './alerts/AlertReducer';
import { MeterConfigReducer } from './meter-config/MeterConfigReducer';
import { AccountReducer } from './organisations/AccountReducer';
import { NotificationReducer } from './register-notifications/NotificationReducer';
import { NodesReducer } from './local-nodes/NodesReducer';
import { SensorsReducer } from './sensors/SensorsReducer';

const reducers = {
  auth: AuthReducer,
  notification: Notification,
  pagination: Pagination,
  settings: SettingsReducer,
  analytics: AnalyticsReducer,
  users: UserReducer,
  accounts: AccountReducer,
  registerNotifications: NotificationReducer,
  date: DateReducer,
  devices: DeviceReducer,
  localNodes: NodesReducer,
  sensors: SensorsReducer,
  peak: PeakReducer,
  meter: MeterListReducer,
  alert: AlertReducer,
  meterConfig: MeterConfigReducer,
  clients: ClientsReducer,
};

export default reducers;
