import {
  GET_ALERTS,
  GET_ALERT,
  GET_ALERT_TYPE,
  GET_MODE_TYPE,
  ERROR,
  SET_ALERT_DATA,
  NEW_ALERT,
  EDITABLE,
  RESET,
  GET_NODES,
} from './Types';

const initAlert = {
  state: 'ENABLED',
  applicableSensorType: 'LIGHT',
  description: '',
  directionIndicator: 'GREATER',
  name: '',
  thresholdLevel: 0,
  type: 'ROLLING_TIME_PERIOD',
  localNodeIds: [],
  rollingTimeHour: '',
  enabledFrom: '00:00:00',
  enabledTo: '01:00:00',
};

const initState = {
  list: {
    items: null,
    totalCount: 0,
  },
  nodesList: {
    items: null,
    totalCount: 0,
  },
  alert: null,
  types: null,
  modes: null,
  errors: null,
  alertData: initAlert,
  editable: initAlert,
  newAlert: initAlert,
};

export const AlertReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NODES:
      return {
        ...state,
        nodesList: action.payload,
      };
    case GET_ALERTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case GET_ALERT_TYPE:
      return {
        ...state,
        types: action.payload,
      };
    case GET_MODE_TYPE:
      return {
        ...state,
        modes: action.payload,
      };
    case SET_ALERT_DATA:
      return {
        ...state,
        alertData: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case NEW_ALERT:
      return {
        ...state,
        newAlert: action.payload,
      };
    case EDITABLE:
      return {
        ...state,
        editable: action.payload,
      };
    case RESET:
      return {
        ...state,
        alertData: initAlert,
      };
    default:
      return state;
  }
};
