export const GET_PEAKS = 'GET_PEAKS';
export const GET_PEAK = 'GET_PEAK';
export const ADD_PEAK = 'ADD_PEAK';
export const UPDATE_PEAK = 'UPDATE_PEAK';
export const DELETE_PEAK = 'DELETE_PEAK';
export const SET_PEAK_EDITABLE = 'SET_PEAK_EDITABLE';
export const SET_CURRENT_PEAK = 'SET_CURRENT_PEAK';
export const RESET_PEAK_FORM = 'RESET_PEAK_FORM';
export const SET_PEAK_PERIODS = 'SET_PEAK_PERIODS';
export const SET_ALL_YEAR = 'SET_ALL_YEAR';
export const SET_PEAK_TYPE = 'SET_PEAK_TYPE';
export const SET_PEAK = 'SET_PEAK';
export const SET_TIME = 'SET_TIME';
export const RESET_PEAK = 'RESET_PEAK';
