export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_DEVICE_SENSOR = 'GET_DEVICE_SENSOR';
export const SET_DEVICE_SENSOR_RANGE = 'SET_DEVICE_SENSOR_RANGE';
export const CLEAR_SENSOR_DATA = 'CLEAR_SENSOR_DATA';
export const ADD_DEVICE = 'ADD_DEVICE';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const SELECT_DEVICE = 'SELECT_DEVICE';
export const SET_DEVICE_EDITABLE = 'SET_DEVICE_EDITABLE';
export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE';
export const RESET_DEVICE_FORM = 'RESET_DEVICE_FORM';
export const GET_DEVICE_TYPES = 'GET_DEVICE_TYPES';
export const SET_SELETED_DEVICE_TYPE = 'SET_SELETED_DEVICE_TYPE';
export const GET_DEVICE_CONFIG = 'GET_DEVICE_CONFIG';
