import {
  GET_USERS,
  GET_USER,
  GET_ROLES,
  GET_PERMS,
  CHECK_MAIL,
  GET_USER_TYPE,
  GET_MODE_TYPE,
  ERROR,
  SET_USER_EDITABLE,
} from './Types';

const initState = {
  list: {
    items: null,
    totalCount: 0,
  },
  user: {
    id: '',
    permissions: [
      {
        name: '',
        permissionGroup: '',
      },
    ],
    roles: [
      {
        name: '',
      },
    ],
    type: '',
    user: {
      dateOfBirth: '',
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      phone: '',
    },
    userName: '',
  },
  types: null,
  modes: null,
  errors: null,
};

export const UserReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_PERMS:
      return {
        ...state,
        perms: action.payload,
      };
    case CHECK_MAIL:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case GET_USER_TYPE:
      return {
        ...state,
        types: action.payload,
      };
    case GET_MODE_TYPE:
      return {
        ...state,
        modes: action.payload,
      };
    case SET_USER_EDITABLE:
      return {
        ...state,
        editable: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
