import {
  GET_PEAKS,
  GET_PEAK,
  DELETE_PEAK,
  ADD_PEAK,
  SET_PEAK_EDITABLE,
  SET_CURRENT_PEAK,
  RESET_PEAK_FORM,
  RESET_PEAK,
  SET_PEAK_PERIODS,
  SET_ALL_YEAR,
  SET_PEAK_TYPE,
  SET_PEAK,
  SET_TIME,
} from './Types';

const getPeakJSON = () => ({
  name: '',
  summerPeriods: {
    mondayFridayPeriods: [],
    saturdaySundayPeriods: [],
  },
  winterPeriodConfig: {
    end: 'MARCH',
    endDay: '1',
    start: 'FEBRUARY',
    startDay: '3',
    winterEnabled: false,
  },
  winterPeriods: {
    mondayFridayPeriods: [],
    saturdaySundayPeriods: [],
  },
});

const initState = {
  list: [],
  current: null,
  editable: null,
  peakPeriods: [],
  allYear: null,
  newPeak: getPeakJSON(),
  time: {
    start: '',
    end: '',
  },
};

export const PeakReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PEAKS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_PEAK:
      return {
        ...state,
        current: action.payload,
      };
    case ADD_PEAK:
      return {
        ...state,
        list: [...state.list, action.payload],
        current: action.payload,
      };

    case DELETE_PEAK:
      return {
        ...state,
        list: state.list.filter(item => item.uuid !== action.payload),
      };
    case SET_PEAK_EDITABLE:
      return {
        ...state,
        editable: action.payload,
      };

    case SET_CURRENT_PEAK:
      return {
        ...state,
        current: action.payload,
      };

    case RESET_PEAK:
      return {
        ...state,
        newPeak: getPeakJSON(),
        peakType: null,
      };

    case RESET_PEAK_FORM:
      return {
        ...state,
        editable: null,
      };

    case SET_PEAK_PERIODS:
      return {
        ...state,
        peakPeriods: [...state.peakPeriods, action.payload],
      };

    case SET_TIME:
      return {
        ...state,
        time: {
          ...state.time,
          ...action.payload,
        },
      };
    case SET_PEAK:
      return {
        ...state,
        newPeak: action.payload,
      };
    case SET_ALL_YEAR:
      return {
        ...state,
        allYear: action.payload,
      };
    case SET_PEAK_TYPE:
      return {
        ...state,
        peakType: action.payload,
      };
    default:
      return state;
  }
};
