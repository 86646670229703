import {
  GET_NODE,
  GET_NODES,
  SET_NODE_DATA,
  GET_TIME_ZONES,
  ERROR,
} from './Types';

const initState = {
  list: {
    items: null,
    totalCount: 0,
  },
  users: {
    items: null,
    totalCount: 0,
  },
  notification: null,
  types: null,
  modes: null,
  errors: null,
};

export const NodesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_NODES:
      return {
        ...state,
        list: action.payload,
      };
    case GET_NODE:
      return {
        ...state,
        localNode: action.payload,
      };
    case SET_NODE_DATA:
      return {
        ...state,
        localNode: action.payload,
      };
    case GET_TIME_ZONES:
      return {
        ...state,
        timeZones: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
