import {
  POST_METERCONFIG,
  ERROR,
  SELECTED_CONFIG,
  SET_METER_CONFIG_EDIT,
  GET_METERCONFIG,
  GET_LOGIN_PASSWORD,
} from './Types';

const initialState = {
  meterconfig: null,
  errors: null,
  selected: null,
  listmeterconfig: null,
  loginconfig: { login: '', password: '' },
};

export const MeterConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_METERCONFIG:
      return {
        ...state,
        meterconfig: action.payload,
      };
    case GET_METERCONFIG:
      return {
        ...state,
        listmeterconfig: action.payload,
      };
    case SELECTED_CONFIG:
      return {
        ...state,
        selected: action.payload,
      };
    case SET_METER_CONFIG_EDIT:
      return {
        ...state,
        editable: action.payload,
      };
    case GET_LOGIN_PASSWORD:
      return {
        ...state,
        loginconfig: action.payload,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
