export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_ROLES = 'GET_ROLES';
export const GET_PERMS = 'GET_PERMS';
export const CHECK_MAIL = 'CHECK_MAIL';
export const GET_USER_TYPE = 'GET_USER_TYPE';
export const GET_MODE_TYPE = 'GET_MODE_TYPE';
export const ERROR = 'PERSON_ERROR';
export const ADD_NOT = 'ADD_NOTIFICATION';
export const CLEAR_NOT = 'CLEAR_NOTIFICATION';
export const SET_USER_EDITABLE = 'SET_USER_EDITABLE';
