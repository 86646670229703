import { GET_ACCOUNTS, GET_ACCOUNT, CHECK_MAIL, ERROR } from './Types';

const initState = {
  list: {
    items: null,
    totalCount: 0,
  },
  account: null,
  types: null,
  modes: null,
  errors: null,
};

export const AccountReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case CHECK_MAIL:
      return {
        ...state,
        account: { ...state.account, ...action.payload },
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
